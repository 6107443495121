<!--
 * @Author: xiaziwen
 * @Date: 2021-01-06 16:34:53
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-13 12:19:41
 * @FilePath: \crm-education\src\views\teacher\home\index.vue
-->
<template>
  <div>教师空间首页</div>
</template>

<script>
export default {
  name: 'teacher_home',
  data () {
    return {}
  },
  mounted () {
    this.$router.push('/teacher/course')
  }
}
</script>